html {
  /* Correct the line height in firefox.
  Chrome seams to round computed line-height:
  16px (font-size) * 1.15 (line-height) = 18.4px (line-height);
  But chrome uses 18px
  */
  line-height: 1.15;
}
h1 {
  font-size: 2em; /* Correct the font size and margin on `h1` elements within `section` and `article` contexts in Chrome, Firefox, and Safari. */
  margin: 0.67em 0;
}
:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0; /* Remove the margin on nested lists in Chrome and Safari. */
}
:where(hr) {
  color: inherit; /* Correct the inheritance of border color in Firefox. */
}
:where(abbr[title]) {
  text-decoration: underline;
  text-decoration: underline dotted; /* Add the correct text decoration in Safari. */
}
:where(b, strong) {
  font-weight: bolder; /* Add the correct font weight in Chrome and Safari. */
}
:where(small) {
  font-size: 80%; /* Add the correct font size in all browsers. */
}
:where(table) {
  border-color: currentColor; /* Correct table border color in Chrome and Safari. */
}
textarea, /* textarea: Firefox and Safari. */
button, input, select /* Safari. */ {
  margin: 0;
}
button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; /* Correct the inability to style buttons in iOS and Safari. */
}
[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari. */
  outline-offset: -2px; /* Correct the outline style in Safari. */
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto; /* Correct the cursor style of increment and decrement buttons in Safari. */
}
::placeholder { /* Correct the text style of placeholders in Chrome and Safari. */
  color: inherit;
  opacity: 0.54;
}
::-webkit-search-decoration {
  -webkit-appearance: none; /* Remove the inner padding in Chrome and Safari on macOS. */
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* Correct the inability to style upload buttons in iOS and Safari. */
  font: inherit; /* Change font properties to `inherit` in Safari. */
}
:where(:-moz-ui-invalid) { /* Remove the additional :invalid styles in Firefox. */
  box-shadow: none;
}
dialog { /* Add the correct styles in Safari 14 */
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
summary { /* Add the correct display in safari 14 */
  display: list-item;
}
